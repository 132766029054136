@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body,
html {
  background-color: #f6f6f6;
}

* {
  font-family: "Poppins", sans-serif;
  color: #343b58;
}

p body h1 h2 {
  font-family: "Poppins", sans-serif;
  color: #343b58;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #343b58;
}

body,
h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #343b58;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #8589a7;
  margin-top: 0px;
  margin-bottom: 10px;
}

.landing-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100vw;
}

.parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-left: 50px;
}

/* @media (max-width: 90vh) {
  .ant-card {
    margin: 0 20px;
  }
} */

.ant-card {
  width: 90vh;
  border-width: 3px;
  border-color: #343b58;
  box-shadow: 7px 7px #343b58;
  border-radius: 45px;
}

.ant-card .ant-card-head-title {
  font-size: 35px;
  font-weight: 500;
  margin: 12px;
}

.ant-card .ant-card-head {
  background: #faadbb;
  border-width: 3px;
  border-color: #343b58;
  border-radius: 43px 43px 0px 0px;
}

.ant-card .ant-card-body {
  font-size: 18px;
  margin: 2%;
}

.skillsGrid {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.gridItem {
  padding: 6px;
  text-align: left;
  color: #8589a7;
}

.bullet {
  width: 15px;
  stroke: #f7768e;
  stroke-width: 1;
  margin-right: 10px;
}

.circleIcon {
  padding: 1px;
  width: 30px;
  stroke: #343b58;
  stroke-width: 1.2;
  fill: #f6f6f6;
  margin: 0;
}

.browserCard {
  text-align: left;
  margin: "0px 20px";
}

/* ===================== Landing Page ===================== */
.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ===================== SIDELINES ===================== */
@media (max-width: 750px) {
  .topSideline {
    display: none !important;
  }

  .bottomSideline {
    display: none !important;
  }
}

.leftSidelineLine {
  background-color: #f7768e;
  border: 0;
  height: 2px;
  border-radius: 1px;
  margin: 10px 10px 10px 0px;
  width: 300px;
}

.rightSidelineLine {
  background-color: #f7768e;
  border: 0;
  height: 2px;
  border-radius: 1px;
  margin: 10px 0px 10px 0px;
  width: 300px;
}

.bottomSideline {
  display: flex;
  justify-content: flex-start;
  transform: rotate(270deg);
  position: fixed;
  left: -150px;
}

.emailSideline {
  transition: stroke 0.3s ease, transform 0.3s ease;
}

.emailSideline:hover {
  color: #f7768e;
  transform: translateY(-2px);
}

.topSideline {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  transform: rotate(90deg);
  position: fixed;
  right: -150px;
}

.sideline-icon {
  stroke: #8589a7;
  width: 20px;
  transform: rotate(270deg);
  transition: stroke 0.3s ease, transform 0.3s ease;
}

.sideline-icon:hover {
  stroke: #f7768e;
  transform: rotate(270deg) translateY(-2px);
}

/* ===================== ANCHOR ===================== */

@media (max-width: 750px) {
  .ant-anchor {
    display: none !important;
  }
}

.ant-anchor-link-title {
  font-size: 16px;
  color: #343b58 !important;
  padding-inline: 30px;
}

.ant-anchor-link-title.ant-anchor-link-title-active {
  font-size: 16px;
  color: #f7768e !important;
  padding-inline: 30px;
}

.ant-anchor {
  display: none;
  padding: 30px 100px 15px 15px !important;
  /* padding-top: 30px !important;
  padding-right: 200px !important; */
  background-color: rgb(255, 255, 255, 0.75);
  justify-content: flex-end;
  backdrop-filter: blur(5px);
}

.ant-anchor-ink-bar {
  color: transparent !important;
}

/* ================EXPERIENCE PAGE==================================== */

.ant-tabs-nav-list {
  background-color: #8589a7;
  border-radius: 0px 0px 0px 43px;
  color: white;
  border-right: 3px solid #000000;
}

/* background color of active tabs */
.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #636681;
  color: white;
}

/* "Remove" colored ink bar on the side for active tabs */
.ant-tabs-ink-bar {
  background: transparent !important;
}

/* text color of active tabs */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

/* Texts of side tabs */
.ant-tabs-tab-btn {
  color: white;
  padding: 10px;
  font-weight: 500;
  font-size: large;
}

/* aligning and coloring folder icons*/
.tab-label {
  display: flex;
  align-items: center;
}

.tab-label .folderIcon {
  width: 25px;
  height: 25px;
  fill: #f6f6f6;
  padding-right: 15px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  /* Custom border color for active tab */
  border-color: #636681;
}

.exp-title {
  color: #343b58;
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.exp-title-pink {
  color: hsl(349, 100%, 74%);
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: stroke 0.3s ease, transform 0.3s ease;
}

.exp-title-pink:hover {
  color: #c5465e;
  transform: translateY(-2px);
}

.exp-title-secondary {
  color: #8589a7;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
}

.ant-tabs-tab {
  margin: 0px !important;
  padding: 10px 24px !important;
}

.exp-bullet-text {
  padding: 6px;
  text-align: left;
  font-size: 17px;
  color: #8589a7;
  display: flex;
}

.exp-bullet {
  width: 25px;
  stroke: #f7768e;
  stroke-width: 1;
  padding: 10px;
}

/* ===================== Projects Page ===================== */

.proj-title {
  color: #343b58;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.proj-photo {
  width: 400px;
  border-radius: 10px;
  border: 2px solid #343b58;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 4px 4px 0px 0px #343b58;
}

.proj-content {
  color: #8589a7;
  font-size: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.proj-tech-list {
  color: #f7768e;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.line {
  background-color: #343b58;
  color: #343b58;
  border-color: #343b58;
  height: 1px;
  border-radius: 2px;
  margin-block: 50px;
}

/* ===================== Contact Page ===================== */

.contact-bold {
  color: #343b58;
  font-size: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

.contact-icon {
  stroke: #8589a7;
  width: 20px;
  transition: stroke 0.3s ease, transform 0.3s ease;
}

.contact-icon:hover {
  stroke: #f7768e;
  width: 20px;
  transform: translateY(-2px);
}
